/**
-----------------------------------------------------
  modules/_connect-with-us.scss
-----------------------------------------------------
**/

.connect-with-us {
    @include bp-at-least($bp__md) { margin-bottom: 11rem; }
    @include bp-at-least($bp__md) { margin-top: 5rem; }
    padding: 0 0 100px;
    @include bp-at-least($bp__md) { padding: 0; }
    position: relative;
}
    .connect-with-us-wrapper {
        @include bp-at-least($bp__md) { max-width: 50%; }
    }
        .connect-with-us-heading {
            font-size: 2rem;
            @include bp-at-least($bp__md) { font-size: 2.4rem; }
            &:after {
                border-bottom: solid 1px $pine--20;
                content: '';
                display: block;
                height: .75rem;
                margin-bottom: 1rem;
                width: 80px;
            }
        }
        .connect-with-us-img {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;

            box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.20);

            display: none;
            @include bp-at-least($bp__md) { display: block; }

            height: 520px;

            margin-left: 40px;

            position: absolute;
            top: -240px;
            left: 50%;
            width: 50%;
            z-index: 100;

            &:before {
                background-color: $moss;
                bottom: 0;
                content: '';
                display: block;
                left: 0;
                opacity: .5;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 10;
            }
        }
