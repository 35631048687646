/**
-----------------------------------------------------
  elements/_headings.scss
-----------------------------------------------------
**/

h1,h2,h3,h4,h5,h6 {
    @extend %font--secondary--bold;
    color: $pine;
    font-size: 1.25rem;
    line-height: 1.625em;
    margin-bottom: 1rem;
    margin-top: 0;
    &.sans-serif { @extend %font--primary--bold; }
    &.uppercase { text-transform: uppercase; }
    &.centered { text-align: center; }
    &.no-margin {
        margin-bottom: 0;
        margin-top: 0;
    }
    &.txt-white { color: $white; }
    &.btm-border {
        &:after {
            border-bottom: solid 1px $pine;
            content: '';
            display: block;
            height: 1rem;
            margin-bottom: 4rem;
            width: 80px;
        }
    }
    &.btm-border--white {
        &:after {
            border-bottom: solid 1px $white;
            content: '';
            display: block;
            height: 1rem;
            margin-bottom: 4rem;
            width: 80px;
        }
    }
}
.h1 { font-size: 2.8rem; }
.h2 { font-size: 2rem; }
.h3 { font-size: 1.8rem; }
.h4 { font-size: 1.4rem; }
.h5 { font-size: 1.2rem; }
.h6 { font-size: 1rem; }
.subheading {
    font-size: 1.8rem;
    @include bp-at-least($bp__md) { font-size: 2.4rem; }
}
