/**
-----------------------------------------------------
  elements/_icons.scss
-----------------------------------------------------
**/

.icon {
    fill: $canary;
    height: 16px;
    width: 16px;
}