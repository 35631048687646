/**
-----------------------------------------------------
  base/_fonts.scss
-----------------------------------------------------
**/

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/ProximaNova-Regular.eot');
	src: url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-Regular.woff') format('woff'),
		url('../fonts/ProximaNova-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/ProximaNova-RegularIt.eot');
	src: url('../fonts/ProximaNova-RegularIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-RegularIt.woff') format('woff'),
		url('../fonts/ProximaNova-RegularIt.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}
@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/ProximaNova-Bold.eot');
	src: url('../fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-Bold.woff') format('woff'),
		url('../fonts/ProximaNova-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/ProximaNova-BoldIt.eot');
	src: url('../fonts/ProximaNova-BoldIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-BoldIt.woff') format('woff'),
		url('../fonts/ProximaNova-BoldIt.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}
%font--primary {
	font-family: 'ProximaNova';
	font-weight: normal;
	font-style: normal;
}
%font--primary--bold {
	font-family: 'ProximaNova';
	font-weight: bold;
	font-style: normal;
}

/**-----------------------------------------------**/

@font-face {
	font-family: 'DomaineDisplay';
	src: url('../fonts/DomaineDisplay-Regular.eot');
	src: url('../fonts/DomaineDisplay-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/DomaineDisplay-Regular.woff') format('woff'),
		url('../fonts/DomaineDisplay-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'DomaineDisplay';
	src: url('../fonts/DomaineDisplay-RegularItalic.eot');
	src: url('../fonts/DomaineDisplay-RegularItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/DomaineDisplay-RegularItalic.woff') format('woff'),
		url('../fonts/DomaineDisplay-RegularItalic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}
@font-face {
	font-family: 'DomaineDisplay';
	src: url('../fonts/DomaineDisplay-SemiboldItalic.eot');
	src: url('../fonts/DomaineDisplay-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/DomaineDisplay-SemiboldItalic.woff') format('woff'),
		url('../fonts/DomaineDisplay-SemiboldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}
@font-face {
	font-family: 'DomaineDisplay';
	src: url('../fonts/DomaineDisplay-Semibold.eot');
	src: url('../fonts/DomaineDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/DomaineDisplay-Semibold.woff') format('woff'),
		url('../fonts/DomaineDisplay-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}
%font--secondary {
	font-family: 'DomaineDisplay';
	font-weight: normal;
	font-style: normal;
}
%font--secondary--bold {
	font-family: 'DomaineDisplay';
	font-weight: 600;
	font-style: normal;
}
