/**
-----------------------------------------------------
  base/_breakpoints.scss
-----------------------------------------------------
**/

$breakpoints: (
    n:  0,
    xs: 20rem,
    sm: 34.375rem,
    md: 48rem,
    lg: 64rem,
    xl: 78.5rem,
    xxl: 100rem
);
$bp__xs: map-get($breakpoints, xs); // Extra small: Small mobile
$bp__sm: map-get($breakpoints, sm); // Small: Large mobile
$bp__md: map-get($breakpoints, md); // Medium: Tablet
$bp__lg: map-get($breakpoints, lg); // Large: Landscape tablet, small desktop
$bp__xl: map-get($breakpoints, xl); // Extra Large: Large desktop
$bp__xxl: map-get($breakpoints, xxl); // Double Extra Large: Huge desktop

/**-----------------------------------------------**/

@mixin bp-at-least($device-width) {
    @media screen and (min-width: $device-width) {
        @content;
    }
}
@mixin bp-until($device-width) {
    @media screen and (max-width: $device-width - .01) {
        @content;
    }
}
@mixin bp-between($device-width, $device-width2) {
    @media screen and (min-width: $device-width) and (max-width: $device-width2 - .01) {
        @content;
    }
}
