/**
-----------------------------------------------------
  modules/_team-members.scss
-----------------------------------------------------
**/

.team-members {
    section {
        @include bp-at-least($bp__sm) { float: left; }
        text-align: center;
        @include bp-between($bp__sm, $bp__md) { width: 46%; }
        @include bp-at-least($bp__md) { width: 28%; }
        @include bp-until($bp__sm) {
            &:not(:last-of-type) { margin-bottom: 3rem; }
        }

        &:not(:nth-of-type(2n)) {
            @include bp-between($bp__sm, $bp__md) { margin-right: 8%; }
        }
        &:nth-of-type(2n+1) {
            @include bp-between($bp__sm, $bp__md) { clear: both; }
        }
        &:nth-of-type(-n+4) {
            @include bp-between($bp__sm, $bp__md) { margin-bottom: 3rem; }
        }

        &:not(:nth-of-type(3n)) {
            @include bp-at-least($bp__md) { margin-right: 8%; }
        }
        &:nth-of-type(3n+1) {
            @include bp-at-least($bp__md) { clear: both; }
        }
        &:nth-of-type(-n+3) {
            @include bp-at-least($bp__md) { margin-bottom: 3rem; }
        }
        .team-members-name {
            a {
                color: $pine;
                &:hover {
                    opacity: .6;
                    .team-member-photo {
                        opacity: .6;
                    }
                }
            }
        }
        .team-member-photo {
            box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.20);
            display: block;
            margin: 0 auto 1rem;
            max-width: 300px;
            transition: all 0.2s ease-in-out;
            width: 100%;
        }
    }
}
