/**
-----------------------------------------------------
  modules/_main.scss
-----------------------------------------------------
**/

.main {
    overflow: hidden;
}
    .main-section {
        padding: 100px 0;
        &.bgd-alternate { background-color: $sand; }
        &.bgd-highlight { background-color: $canary; }
    }
