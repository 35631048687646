/**
-----------------------------------------------------
  modules/_why-acts.scss
-----------------------------------------------------
**/

.why-acts {
    position: relative;
}
    .why-acts-img {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.20);

        display: none;
        @include bp-at-least($bp__md) { display: block; }

        height: 460px;

        margin-left: 40px;

        position: absolute;
        bottom: -120px;
        left: 50%;
        width: 50%;

        &:before {
            background-color: $moss;
            bottom: 0;
            content: '';
            display: block;
            left: 0;
            opacity: .5;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 10;
        }
    }

    .why-acts-vision-img {
        margin-top: 6rem;
    }
