/**
-----------------------------------------------------
  elements/_buttons.scss
-----------------------------------------------------
**/

button {
    &:focus,
    &:active {
        outline: none;
        box-shadow: none;
        border: none;
    }
}
.btn {
    display: inline-block;
    background: $canary;
    border: none;
    color: $white;
    cursor: pointer;
    padding: .8em 3em;
    text-transform: uppercase;
    text-decoration: none;
    transition: all .25s ease-in-out;
    &:hover,
    &:focus,
    &:active {
        background: $canary--60;
        color: $white;
        outline: none;
    }
    &--alt {
        @extend .btn;
        background: $pine;
        color: $white;
        &:hover,
        &:focus,
        &:active {
            background: $pine--60;
            color: $white;
        }
    }
    &--extra-wide {
        padding-left: 5em;
        padding-right: 5em;
    }
    &.full-width {
        display: block;
    }
}
