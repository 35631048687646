/**
-----------------------------------------------------
  elements/_text.scss
-----------------------------------------------------
**/

p {
    font-size: 1rem;
    line-height: 1.75em;
    margin-bottom: 1rem;
    margin-top: 0;
    &.serif { @extend %font--secondary; }
    &.large { font-size: 1.125rem; }
    &.small { font-size: .875rem; }
    &.no-margin {
        margin-bottom: 0;
        margin-top: 0;
    }
    &.txt-white { color: $white; }
    &.txt-layout-left {
        @include bp-at-least($bp__sm) { padding-right: 25%; }
    }
    &.txt-layout-left-50 {
        @include bp-at-least($bp__sm) { padding-right: 50%; }
    }
}

.uppercase { text-transform: uppercase; }
