/**
-----------------------------------------------------
  modules/_callout-area.scss
-----------------------------------------------------
**/

.callout-area {
    background-color: $white;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
    padding: 30px;
    @include bp-at-least($bp__sm) { padding: 100px; }
    position: relative;
    z-index: 20;
}
