/**
-----------------------------------------------------
  elements/_blockquote.scss
-----------------------------------------------------
**/

blockquote {
    @extend %font--secondary--bold;
    color: $pine;
    max-width: 80%;
    p {
        font-size: 1.5rem;
        @include bp-at-least($bp__md) { font-size: 2rem; }
        margin-bottom: 0;
    }
    &.bordered {
        margin-bottom: 3em;
        margin-top: 3rem;
        padding: 2rem 0;
        border-bottom: solid 1px $pine--20;
        border-top: solid 1px $pine--20;
    }
}
