/**
-----------------------------------------------------
  base/_helpers.scss
-----------------------------------------------------
**/

.group:after {
    clear: both;
    content: "";
    display: table;
}
.hidden {
	display: none;
}
.visible {
    display: block;
}
