/**
-----------------------------------------------------
  elements/_body.scss
-----------------------------------------------------
**/

body {
    @extend %font--primary;
    background: $white;
    color: $gray;
    font-size: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}
