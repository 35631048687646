/**
-----------------------------------------------------
  base/_mixins.scss
-----------------------------------------------------
**/
@mixin clearfix {
    &:after {
        clear: both;
        content: '';
        display: table;
    }
}
@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    transform: translateY(-50%);
}
