/**
-----------------------------------------------------
  elements/_images.scss
-----------------------------------------------------
**/

img {
    max-width: 100%;
}

.img-width-wrapper {
    display: block;
    margin: 0 auto;
    @include bp-at-least($bp__sm) { width: 80%; }
}
