/**
-----------------------------------------------------
  base/_colors.scss
-----------------------------------------------------
**/

$black: #000000;
$gray: #6d6e70;
$white: #FFFFFF;
$white--60: rgba($white, .6);
$canary: #F7C337;
$canary--60: rgba($canary, .6);
$sand: #FAF7DF;
$sand--60: rgba($sand, .6);
$aqua: #A7D5CD;
$seafoam: #BADAAA;
$moss: #D1D5C2;
$pine: #3A5F5C;
$pine--20: rgba($pine, .2);
$pine--60: rgba($pine, .6);
