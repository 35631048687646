/**
-----------------------------------------------------
  elements/_html.scss
-----------------------------------------------------
**/

html {
    font-size: 93.75%;
    height: 100%;
    margin: 0;
    padding: 0;
}
* {
    box-sizing: border-box;
}
