/**
-----------------------------------------------------
  elements/_lists.scss
-----------------------------------------------------
**/

ul, ol {
    @extend %font--primary;
    font-size: 1rem;
    margin-bottom: 1rem;
    margin-top: 0;
    li {
        &:not(:last-child) {
            margin-bottom: .5rem;
        }
        & li:first-child {
            margin-top: .5rem;
        }
    }
    & * {
        font-size: inherit;
        margin-bottom: 0;
    }
}
ul.no-padding, ol.no-padding {
    padding-left: 0;
}
.inline-list li {
    display: inline-block;
}
.block-list {
    display: block;
}
