/**
-----------------------------------------------------
  modules/_contact-section.scss
-----------------------------------------------------
**/

.contact-section {

}
    .contact-section-wrapper {
        background-color: $white;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
    }
        .contact-section-form {
            background-color: $sand;
            padding: 30px;
            @include bp-at-least($bp__sm) { padding: 60px; }

            .form-column {
                @include bp-at-least($bp__sm) { float: left; }
                @include bp-at-least($bp__sm) { margin-bottom: 1rem; }
                @include bp-at-least($bp__sm) { width: 45%; }
                &:not(:last-of-type) {
                    @include bp-at-least($bp__sm) { margin-right: 10%; }
                }
            }

            .form-submit-container {
                clear: both;
                text-align: center;
            }
        }
