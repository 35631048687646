/**
-----------------------------------------------------
  modules/_vimeo-embed.scss
-----------------------------------------------------
**/

.vimeo-embed {
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.20);
    box-sizing: content-box;
    display: block;
    height: 0;
    margin: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    iframe {
        background-color: $sand--60;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
    // &.border-fix--black iframe {
    //     border: solid 1px $black;
    // }
    // &.border-fix--canary iframe {
    //     border: solid 1px $canary;
    // }
    // &.border-fix--sand iframe {
    //     border: solid 1px $sand;
    // }
    // &.border-fix--white iframe {
    //     border: solid 1px $white;
    // }
}
.vimeo-embed-wrapper {
    @include bp-at-least($bp__sm) { width: 80%; }
    &.vimeo-layout-centered {
        margin-left: auto;
        margin-right: auto;
    }
    &.vimeo-layout-full {
        @include bp-at-least($bp__sm) { width: 100%; }
    }
    &.vimeo-layout-left {
        @include bp-at-least($bp__sm) { padding-right: 25%; }
    }
}
