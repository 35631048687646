/**
-----------------------------------------------------
  layout/_general.scss
-----------------------------------------------------
**/

.layout-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
    padding-left: 25px;
    @include bp-at-least($bp__md) { padding-left: 40px; }
    padding-right: 25px;
    @include bp-at-least($bp__md) { padding-right: 40px; }
    &:not(.non-parent) {
        position: relative;
    }
    &:after {
        clear: both;
        content: "";
        display: table;
    }
}
.parent {
    position: relative;
}
.push-left {
    float: left;
}
.push-right {
    float: right;
}
.text-centered {
    text-align: center;
}
.centered-width {
    margin: 0 auto;
}
.top-breathing-room {
    margin-top: 2rem;
}
.top-breathing-room-4x {
    margin-top: 4rem;
}
.top-breathing-room-6x {
    margin-top: 6rem;
}
.bottom-breathing-room, .bottom-breathing-room-2x {
    margin-bottom: 2rem;
}
.bottom-breathing-room-4x {
    margin-bottom: 4rem;
}
.bottom-breathing-room-6x {
    margin-bottom: 6rem;
}
