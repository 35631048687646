/**
-----------------------------------------------------
  modules/_header.scss
-----------------------------------------------------
**/

.header {

}
    .header-nav {
        padding: 35px 0;
    }
        .header-nav-logo {
            height: 29px;
            width: 102px;
        }
        .header-nav-mobile-trigger {
            cursor: pointer;
            @include bp-at-least($bp__md) { display: none; }
            float: right;
            margin-top: 8px;
        }
            .header-nav-mobile-open {
                &.clicked-open { display: none; }
            }
            .header-nav-mobile-close {
                display: none;
                &.clicked-open { display: block; }
            }
        .header-nav-link-list {
            @include bp-at-least($bp__md) { @include vertical-align(absolute); }
            display: block;
            @include bp-until($bp__md) { display: none; }
            list-style: none;
            margin-top: 30px;
            @include bp-at-least($bp__md) { margin: 0; }
            padding: 0;
            @include bp-at-least($bp__md) { right: 40px; }
            &.clicked-open { display: block; }
        }
            .header-nav-link-list-item {
                display: block;
                @include bp-at-least($bp__md) { display: inline-block; }
                @include bp-at-least($bp__md) { float: left; }
                text-align: center;
                &:not(:last-child) {
                    margin: 0 0 1.25rem 0;
                    @include bp-at-least($bp__md) { margin: 0 10px 0 0; }
                }
                a {
                    @extend %font--primary--bold;
                    color: $pine;
                    display: inline-block;
                    padding: 8px 10px;
                    position: relative;
                    &:hover {
                        color: $pine--60;
                        &.current:after {
                            border-bottom: 2px solid $pine--60;
                        }
                    }
                    &.current:after {
                        border-bottom: 2px solid $pine;
                        bottom: -2px;
                        content: "";
                        left: 5px;
                        position: absolute;
                        right: 5px;
                        transition: all 0.2s ease-in-out;
                        z-index: 1;
                    }
                    &.nav--contact {
                        color: $canary;
                        border: 1px solid $canary;
                        @include bp-at-least($bp__md) { margin-left: 10px; }
                        padding: 7px 19px; // Default 8px 20px minus border width
                        &:hover {
                            border: 1px solid $canary--60;
                            color: $canary--60;
                        }
                        &.current {
                            background-color: $canary;
                            color: $white;
                            &:after { display: none; }
                        }
                    }
                }
            }
    .header-hero {
        background-color: $sand;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(../img/hero.jpg);
        padding: 80px 0;
        @include bp-at-least($bp__md) { padding: 140px 0; }
        position: relative;
        &:before {
            background-color: $canary;
            bottom: 0;
            content: '';
            display: block;
            opacity: .7;
            padding-right: 80px;
            position: absolute;
            top: 0;
            width: 50%;
        }
        .layout-container:after {
            border-bottom: solid 1px $white;
            bottom: 0;
            content: '';
            display: block;
            width: 100%;
        }
        .header-hero-text {
            font-size: 2rem;
            @include bp-at-least($bp__md) { font-size: 3rem; }
            line-height: 3rem;
            @include bp-at-least($bp__md) { line-height: 4rem; }
            @include bp-at-least($bp__md) { max-width: 90%; }
            padding-bottom: 1rem;
            @include bp-at-least($bp__md) { padding-bottom: 1.5rem; }
            text-shadow: 0 0px 10px rgba(0,0,0,0.1);
        }
    }
