/**
-----------------------------------------------------
  elements/_forms.scss
-----------------------------------------------------
**/

form {

}

form.form--primary-contact {

    & ul {
        list-style: none;
        margin: 0;
        padding: 0;

        & li {
            margin-bottom: 1rem;
            width: 100%;

            label {
                @extend %font--primary--bold;
                color: $pine;
                display: block;
                font-size: .625rem;
                margin-bottom: .25rem;
                text-transform: uppercase;
            }

            input[type="text"] {
                background-color: $white;
                border: 1px solid $pine--20;
                border-radius: 0;
                color: $pine;
                font-size: .875rem;
                padding: .75rem;
                width: 100%;
                -webkit-appearance: none;
            }

            input[type="radio"] {
                font-size: .75rem;
                margin-right: .5rem;
            }

            textarea {
                background-color: $white;
                border: 1px solid $pine--20;
                border-radius: 0;
                color: $pine;
                font-size: .875rem;
                padding: .75rem;
                resize: none;
                width: 100%;
                -webkit-appearance: none;
            }




            fieldset {
                border: none;
                padding: 0;

                label {
                    display: inline-block;
                }
            }

                fieldset legend {
                    @extend label;
                    margin-bottom: .5rem;
                }

                .fieldset-radio-option {
                    margin-left: 10px;
                }


        }
    }
}
