/**
-----------------------------------------------------
  modules/_biblical-core.scss
-----------------------------------------------------
**/

.biblical-core {
    color: $white;
}
    .biblical-core-row {
        @include bp-at-least($bp__md) { display: table; }
        min-height: 300px;
        &.mrg-bottom {
            @include bp-at-least($bp__md) { margin-bottom: 5%; }
        }
        section {
            @include bp-at-least($bp__md) { display: table-cell; }
            margin-bottom: 3rem;
            padding: 3rem;
            @include bp-at-least($bp__md) { padding-left: 4.5em; }
            position: relative;
            text-align: center;
            @include bp-at-least($bp__md) { width: 50%; }
            vertical-align: middle;
            &:after {
                border: solid 1px $white;
                bottom: 0;
                content: '';
                display: block;
                left: 0;
                @include bp-at-least($bp__md) { left: 1.5rem; }
                position: absolute;
                right: 0;
                top: 0;
            }
            &.left {
                @include bp-at-least($bp__md) { padding-left: 3rem; }
                @include bp-at-least($bp__md) { padding-right: 4.5rem; }
                &:after {
                    left: 0;
                    @include bp-at-least($bp__md) { right: 1.5rem; }
                }
            }
            h2:after {
                border-bottom: solid 1px $white;
                content: '';
                display: block;
                height: 1rem;
                margin: 0 auto;
                width: 80px;
            }
        }
    }
