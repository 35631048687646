/**
-----------------------------------------------------
  modules/_footer.scss
-----------------------------------------------------
**/

.footer {

}
    .footer-nav {
        background-color: $pine;
        padding: 60px 0;
    }
        .footer-nav-logo {
            display: block;
            @include bp-at-least($bp__md) { float: left; }
            height: 40px;
            @include bp-at-least($bp__md) { height: 60px; }
            margin: 0 auto 20px;
            @include bp-at-least($bp__md) { margin: 0; }
            width: 40px;
            @include bp-at-least($bp__md) { width: 60px; }
        }
        .footer-nav-link-list {
            @include bp-at-least($bp__md) { @include vertical-align(absolute); }
            display: block;
            list-style: none;
            margin: 0;
            padding: 0;
            @include bp-at-least($bp__md) { right: 40px; }
        }
            .footer-nav-link-list-item {
                display: block;
                @include bp-at-least($bp__md) { display: inline-block; }
                @include bp-at-least($bp__md) { float: left; }
                text-align: center;
                &:not(:last-child) {
                    margin: 0 0 1.25rem 0;
                    @include bp-at-least($bp__md) { margin: 0 10px 0 0; }
                }
                a {
                    @extend %font--primary--bold;
                    color: $white;
                    display: inline-block;
                    padding: 8px 10px;
                    position: relative;
                    &:hover {
                        color: $white--60;
                        &.current:after {
                            border-bottom: 2px solid $white--60;
                        }
                    }
                    &.current:after {
                        border-bottom: 2px solid $white;
                        bottom: -2px;
                        content: "";
                        left: 5px;
                        position: absolute;
                        right: 5px;
                        transition: all 0.2s ease-in-out;
                        z-index: 1;
                    }
                    &.nav--contact {
                        color: $canary;
                        border: 1px solid $canary;
                        @include bp-at-least($bp__md) { margin-left: 10px; }
                        padding: 7px 19px; // Default 8px 20px minus border width
                        &:hover {
                            border: 1px solid $canary--60;
                            color: $canary--60;
                        }
                        &.current {
                            background-color: $canary;
                            color: $white;
                            &:after { display: none; }
                        }
                    }
                }
            }
    .footer-secondary {
        @include clearfix;
        padding: 30px 0;
        text-align: center;
        @include bp-at-least($bp__md) { text-align: left; }

        .footer-contact {
            @include bp-at-least($bp__md) { float: right; }
            font-size: .875rem;
            @include bp-at-least($bp__md) { font-size: .75rem; }
        }
            .footer-contact-phone {
                display: inline-block;
                margin-left: 1rem;
            }
        .footer-copyright {
            @include bp-at-least($bp__md) { float: left; }
            font-size: .75rem;
        }
    }
