/**
-----------------------------------------------------
  modules/_creating-change.scss
-----------------------------------------------------
**/

.creating-change {

}
    .creating-change-item {
        overflow: none;
        position: relative;
        &:nth-of-type(1) {
            margin-top: 1rem;
            @include bp-at-least($bp__md) { margin-top: 6rem; }
        }
        &:nth-of-type(2) {
            margin-top: 2rem;
            @include bp-at-least($bp__md) { margin-top: 20rem; }
        }
        &:nth-of-type(3) {
            margin-top: 2rem;
            @include bp-at-least($bp__md) { margin-top: 10rem; }
        }
    }
        .creating-change-content {
            @include bp-at-least($bp__md) { padding-right: 80px; }
            position: relative;
            @include bp-at-least($bp__md) { width: 50%; }

            &.right-side {
                @include bp-at-least($bp__md) { float: right; }
                @include bp-at-least($bp__md) { padding-left: 80px; }
                @include bp-at-least($bp__md) { padding-right: 0; }
            }
        }
            .creating-change-figure {
                @extend %font--secondary--bold;
                color: $aqua;
                font-size: 6rem;
                left: 0;
                margin: 0;
                padding: 0;
                position: absolute;
                top: -2rem;
                z-index: 0;

                .creating-change-content.right-side & {
                    @include bp-at-least($bp__md) { left: 80px; }
                }
            }
            .creating-change-title {
                border-bottom: 1px solid $pine--20;
                margin: 0 0 2rem 0;
                padding: 1.5rem 0 2rem;
                position: relative;
                z-index: 10;
            }
            .creating-change-text {
                margin-bottom: 2rem;
            }






    .creating-change-img-1 {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);

        display: none;
        @include bp-at-least($bp__md) { display: block; }

        height: 600px;

        margin-left: 40px;

        position: absolute;
        top: -100px;
        left: 50%;
        width: 50%;

        &:before {
            background-color: $moss;
            bottom: 0;
            content: '';
            display: block;
            left: 0;
            opacity: .5;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 10;
        }
    }

    .creating-change-img-2 {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);

        display: none;
        @include bp-at-least($bp__md) { display: block; }

        height: 460px;

        margin-right: 80px;

        position: absolute;
        top: -140px;
        right: 50%;
        width: 50%;

        &:before {
            background-color: $moss;
            bottom: 0;
            content: '';
            display: block;
            left: 0;
            opacity: .5;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 10;
        }
    }

    .creating-change-img-3 {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.20);

        display: none;
        @include bp-at-least($bp__md) { display: block; }

        height: 500px;

        margin-left: 40px;

        position: absolute;
        top: -70px;
        left: 50%;
        width: 50%;

        &:before {
            background-color: $moss;
            bottom: 0;
            content: '';
            display: block;
            left: 0;
            opacity: .5;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 10;
        }
    }
