/**
-----------------------------------------------------
  modules/_bios.scss
-----------------------------------------------------
**/

.bios-section {

}
    .bios-section-wrapper {
        background-color: $white;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
    }
        .bios-section-content {
            background-color: $white;
            padding: 30px;
            @include bp-at-least($bp__sm) { padding: 60px; }

            .team-member-name {
                margin-bottom: .5rem;
            }
            .team-member-title {
                
            }
            .team-member-bio {
                margin-bottom: 2rem;
            }
        }
