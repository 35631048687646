/**
-----------------------------------------------------
  elements/_links.scss
-----------------------------------------------------
**/

a {
    color: $canary;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    &:hover { color: $canary--60; }
    &.white { color: $white; }
    &.faded {
        color: $pine--20;
        &:hover { color: $pine; }
    }
    &.disguised {
        color: $pine;
        &:hover { color: $pine--60; }
    }
}
body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, .35);
}
